.cclab_title {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
}
.cclab_title h3 {
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 25px;
    line-height: 1;
}
.cclab_title p {
    max-width: 600px;
}

.cclab_button {
    width: 100%;
    float: left;
    clear: both;
}
.cclab_button a,
.cclab_button button {
    text-decoration: none;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 18px;
    display: inline-block;
    border-radius: 6px;
    border: 2px solid #7e7e7e;
    padding: 8px 35px 7px 35px;
    transition: all 0.3s ease;
    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}
.cclab_button a:hover,
.cclab_button button:hover {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #1a1a1a;
}
