.cclab_publication {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    // background-color: #f5f8fc;
    padding: 120px 0px 90px 0px;
    @media screen and (max-width: 991px) {
        padding: 90px 0px 60px 0px;
    }
}

.publication_list {
    padding-top: 280px;
}
